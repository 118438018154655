import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Img from "gatsby-image"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const allPosts = data.allMarkdownRemark.nodes
  const emptyQuery = ""
  const [search, setSearch] = useState({
    filteredData: [],
    query: emptyQuery,
  })
  const handleInputChange = event => {
    const query = event.target.value
    const filteredData = allPosts.filter(post => {
      const { description, title } = post.frontmatter
      return (
        description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase())
      )
    })
    setSearch({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = search
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All blogs" />
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="section-title d-flex align-items-center">
              <h1 className="mb-0">Blogs</h1>
              <div className="flex-grow-1 ml-4">
                <input
                  type="text"
                  className="form-control ml-auto"
                  name="search"
                  value={query}
                  placeholder="Search..."
                  onChange={handleInputChange}
                  style={{ maxWidth: "260px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {posts.length ? (
            posts.map(post => {
              const title = post.frontmatter.title || post.fields.slug
              return (
                <div
                  className="col-12 col-sm-8 col-md-6 col-lg-4 mb-5"
                  key={post.fields.slug}
                >
                  <div
                    className="card h-100"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    {post.frontmatter.featured_image && (
                      <Img
                        fluid={
                          post.frontmatter.featured_image.childImageSharp.fluid
                        }
                      />
                    )}
                    <div className="card-body">
                      <h2 className="h4 card-title">
                        {" "}
                        <Link to={`/blogs${post.fields.slug}`} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </h2>
                      <p
                        className="card-text"
                        dangerouslySetInnerHTML={{
                          __html: post.excerpt,
                        }}
                        itemProp="description"
                      />
                    </div>
                    <div className="card-footer text-muted d-flex justify-content-between bg-transparent border-top-0">
                      {post.frontmatter.date}
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <div className="col-12">
              <div className="alert alert-info">No results found.</div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt(pruneLength: 100)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featured_image {
            relativePath
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
